/*
* @Author: 曹俊杰
* @Date: 2024-09-22 10:05:50
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2025-01-07 11:43:03
*/ 核查记录
<template>
    <div class="box">

        <el-card shadow="never" :body-style="{ position: 'relative' }" style="margin-top:16px;">
            <div slot="header" style="position: relative;">
                <hdq-section title="排查记录" more-text="" />
            </div>

            <el-table :data="list" tooltip-effect="dark" current-row-key="detectionId">

                <el-table-column label="核查日期" show-overflow-tooltip width="120px">
                    <template slot-scope="scope">{{ scope.row.createdAt | timeFormat('yyyy-mm-dd hh:MM') }}</template>
                </el-table-column>
                <el-table-column label="姓名" show-overflow-tooltip width="70px">
                    <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column label="手机号" show-overflow-tooltip width="110px">
                    <template slot-scope="scope">{{ scope.row.phone }}</template>
                </el-table-column>
                <el-table-column label="身份证号" show-overflow-tooltip width="100px">
                    <template slot-scope="scope">{{ scope.row.idNumber }}</template>
                </el-table-column>
               
                <el-table-column label="风险排查范围" show-overflow-tooltip width="280px">
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.content }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作人" show-overflow-tooltip width="100px">
                    <template slot-scope="scope">{{ scope.row.emp.name }}</template>
                </el-table-column>
                <el-table-column label="操作">

                    <template slot-scope="scope">
                        <!-- <duration :end="endTime(scope.row.createdAt)" :key="scope.row.detectionId" @onchick="detail(scope.row)">
                            
                        </duration> -->
                        <!-- <daojishi :targetTime="scope.row.createdAt" @onchick="detail(scope.row)"/> -->
                        <span class="blue pointer" @click="detail(scope.row)">查看结果</span>
                    </template>
                </el-table-column>

            </el-table>
            <hdq-pagination small ref="pagination" @change="paginationChange" />
        </el-card>
        <InspectDetail ref="InspectDetail" />
    </div>
</template>

<script>
import hdqPagination from "@/components/hdqPagination.vue";
import daojishi from "@/components/daojishi.vue";
import duration from "@/components/duration.vue";
import InspectDetail from "@/views/Inspect/components/InspectDetail.vue"
export default {
    components: {
        hdqPagination,
        InspectDetail,
        // duration,
    },
    data() {
        return {
            list: [],
            limit: 600,
            nowTime: Date.parse(new Date()).toString().substr(0, 10),
            text: ''
        };
    },
    mounted() {
        this.getDetectionList();
    },

    methods: {
        endTime(time) {
            return (time + 60 * 60 * 24) * 1000
        },
        async getDetectionList(current = 1, size = 15) {
            console.log(size)
            const { status, data } = await this.$api.getDetectionList({ current, size })
            if (status === 200) {
                const { records } = data
                this.list = records
                this.$refs.pagination.createPagination(data)
            }
        },
        paginationChange({ current, size }) {
            this.getDetectionList(current, size)
        },
        detail({ detectionId }) {
            this.$refs.InspectDetail.show(detectionId)
        },
        successClick(scope) {
            const { row: { success, message, fileUrl } } = scope
            if (success) {
                this.$router.push({
                    path: '/inspect/inspectResult',
                    query: {
                        fileUrl: fileUrl
                    }
                })
            } else {
                this.$alert(message, '失败原因', {
                    confirmButtonText: '确定',
                    type: 'error'
                });
            }

        }
    },
};
</script>

<style lang="scss" scoped>
.box {

    .blue {
        color: #0062FF;
    }

    .operate {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        cursor: pointer;

        span:last-child {
            color: #999;
        }
    }
}
</style>
